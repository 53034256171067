<template>
  <div class="addPassenger">
    <div class="addPassenger_box w-1400 margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>客源管理 </el-breadcrumb-item>
          <el-breadcrumb-item>编辑客源</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="addPassenger_main b-c-f">
        <div class="addPassenger_main_head flexs p-t-30 p-b-30">
          <span>基本信息</span>
          <span>（{{ userInfo.name }}）</span>
        </div>
        <div class="addPassenger_main_form">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="性别:">
              <el-select v-model="ruleForm.sex" placeholder="请选择">
                <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="ruleForm.status" placeholder="请选择">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="房源类型:">
              <el-select
                v-model="ruleForm.house_type"
                placeholder="请选择房源类型"
              >
                <el-option
                  v-for="item in housingTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="意向位置:">
              <el-cascader
                size="large"
                :options="regionList"
                v-model="areaList"
                placeholder="请选择意向位置"
                @change="handleChangeCity"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="装修:">
              <el-select
                v-model="ruleForm.finish_type"
                placeholder="请选择装修"
              >
                <el-option
                  v-for="item in fitmentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="意向面积:" prop="purpose_area">
              <ul class="proportion_ul flexs">
                <li>
                  <el-input
                    maxlength="5"
                    v-model="ruleForm.min_purpose_area"
                    placeholder="请输入"
                  ></el-input>
                </li>
                <li class="m-r-10 m-l-10">——</li>
                <li>
                  <el-input
                    maxlength="5"
                    v-model="ruleForm.max_purpose_area"
                    placeholder="请输入"
                  ></el-input>
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="意向房型:">
              <el-select
                v-model="ruleForm.purpose_house_type"
                placeholder="请选择意向房型"
              >
                <el-option
                  v-for="item in roomList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="租住方式:">
              <el-select
                filterable
                v-model="ruleForm.rent_way"
                placeholder="请选择意向房型"
              >
                <el-option
                  v-for="item in wayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出租时长:">
              <el-select
                v-model="ruleForm.rent_ways"
                placeholder="请选择租住方式"
              >
                <el-option
                  v-for="item in waysList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预算:" prop="total_price">
              <ul class="proportion_ul flexs">
                <li>
                  <el-input
                    maxlength="9"
                    v-model="ruleForm.min_total_price"
                    placeholder="请输入"
                  ></el-input>
                </li>
                <li class="m-r-10 m-l-10">——</li>
                <li>
                  <el-input
                    maxlength="9"
                    v-model="ruleForm.max_total_price"
                    placeholder="请输入"
                  ></el-input>
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="是否需要电梯:">
              <el-select v-model="ruleForm.is_elevator" placeholder="请选择">
                <el-option
                  v-for="item in whethersList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否需要车位:">
              <el-select v-model="ruleForm.is_carport" placeholder="请选择">
                <el-option
                  v-for="item in whethersList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="最早入住时间:">
              <el-select v-model="ruleForm.fastest_time" placeholder="请选择">
                <el-option
                  v-for="item in checkTimeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否要离学校近:">
              <el-select v-model="ruleForm.is_school" placeholder="请选择">
                <el-option
                  v-for="item in whethersList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款方式:">
              <el-select v-model="ruleForm.pay_way" placeholder="请选择">
                <el-option
                  v-for="item in payList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="租赁时长:">
              <el-select v-model="ruleForm.rent_time" placeholder="请选择">
                <el-option
                  v-for="item in leaseList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="装修时间:">
              <el-select
                v-model="ruleForm.finish_time"
                placeholder="请选择装修时间"
              >
                <el-option
                  v-for="item in fitmentTimeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户来源:">
              <el-select
                v-model="ruleForm.client_source"
                placeholder="请选择客户来源"
              >
                <el-option
                  v-for="item in sourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户等级" prop="booth_type">
              <el-select v-model="ruleForm.booth_type" placeholder="请选择">
                <el-option
                  v-for="item in classList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="朝向:">
              <el-select
                filterable
                v-model="ruleForm.orientation"
                placeholder="请选择朝向"
              >
                <el-option
                  v-for="item in orientationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客源类型:">
              <el-select v-model="ruleForm.client_type" placeholder="请选择">
                <el-option
                  v-for="item in clientType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="房屋性质:" prop="fang_gou">
              <el-select
                v-model="ruleForm.fang_gou"
                placeholder="请选择房屋性质"
              >
                <el-option
                  v-for="item in buildingList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="锁定小区:">
              <el-select
                @change="changePlat"
                v-model="ruleForm.xiaoqu_id"
                filterable
                remote
                :remote-method="remoteMethod"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in plotList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户贵姓:">
              <el-input
                v-model="ruleForm.kehu_xing"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="第一需求:">
              <el-input
                v-model="ruleForm.xuqiu_yi"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="最高价格:">
              <el-input
                v-model="ruleForm.gao_prc"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="现住到期:">
              <el-date-picker
                v-model="ruleForm.x_daoqi"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="办公地点:">
              <el-input
                v-model="ruleForm.s_xiaoqu"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="锁定学区:">
              <el-input
                v-model="ruleForm.s_xuequ"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签:">
              <ul class="tag fl">
                <li
                  @click="changItem(item)"
                  v-for="(item, index) in tagList"
                  :class="{ active: item.checked }"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="房源标签:">
              <ul class="tag flexs">
                <li
                  @click="item.checked = !item.checked"
                  v-for="(item, index) in tagsList"
                  :class="{ active: item.checked }"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-form-item>
          </el-form>
        </div>
        <ul class="addPassenger_main_ul">
          <!-- <li>
            <div class="addPassenger_main_ul_name">意向小区:</div>
            <div class="addPassenger_main_ul_main">
              <div class="addPassenger_main_ul_add" @click="openPop(1)">
                <img src="../../assets/image/talents/add.png" alt="" />
              </div>
              <div class="addPassenger_main_list">
                <el-tag
                  @close="closePlta(index)"
                  closable
                  v-for="(item, index) in platList"
                  :key="index"
                  >{{ item.name }}</el-tag
                >
              </div>
            </div>
          </li> -->
          <li>
            <div class="addPassenger_main_ul_name">联系方式:</div>
            <div class="addPassenger_main_ul_main">
              <div class="addPassenger_main_ul_add" @click="openPop(2)">
                <img src="../../assets/image/talents/add.png" alt="" />
              </div>
              <div class="addPassenger_main_list">
                <el-tag
                  @close="closePhone(index)"
                  closable
                  v-for="(item, index) in phoneList"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </div>
            </div>
          </li>
        </ul>
        <div class="addPassenger_main_footer center">
          <el-button type="primary" @click="resetForm('ruleForm')"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <!-- 添加手机弹框 -->
    <add-phone ref="phone" @succeed="succeedPhone"></add-phone>
    <!-- 添加小区 -->
    <add-plot ref="plot" @suspend="addPlot"></add-plot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import index from "../../common/index";
import { regionData, CodeToText } from "element-china-area-data";
import addPhone from "@/components/home/AddPhone.vue";
import addPlot from "@/components/home/AddPlot.vue";
export default {
  mixins: [index],
  components: { addPhone, addPlot },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      areaList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      ruleForm: {
        id: "", //
        sex: "",
        name: "",
        house_type: "",
        finish_type: "",
        status: "", //状态：1 未交易；3 有效暂缓；5 无效暂缓；7 我司成交；9 他司成交
        client_type: "", //公私客类型：1 私客；2 公客
        purpose_house_type: "", //意向房型
        is_elevator: "", //是否需要电梯：1 否；2 是
        is_carport: "", //是否需要车位：1 否；2 是
        fastest_time: "", //最早入住时间
        is_school: "", //是否距离学校近：1 否；2 是
        pay_way: "", //付钱方式
        rent_time: "", //租期
        finish_time: "", //装修时间
        client_source: "", //客户来源
        booth_type: "", //客户等级：1=展位；2=A；3=B；4=C；5=D；
        orientation: "", //朝向
        client_label: "", //标签
        rent_way: "", //租住方式
        rent_ways: "", //出租时长
        purpose_area: "", //面积
        total_price: "", //预算
        mobile: "", //手机号
        xiaoqu_id: "", //
        purpose_little_area: "", //
        city: "",
        type: "", //1 求租；2 求购
        min_purpose_area: "",
        max_purpose_area: "", //
        min_total_price: "", //
        max_total_price: "",
        fang_gou: "", //房屋性质
        kehu_xing: "", //客户贵姓
        xuqiu_yi: "", //第一需求
        gao_prc: "", //最高价格
        x_daoqi: "", //现住到期
        s_xuequ: "", //锁定学区
        s_xiaoqu: "", //办公地点
        cheng: "",
        ji: "",
        jing: "",
        ding: "",
        duan: "",
        chang: "",
      },
      housingTypeList: [
        {
          value: 2,
          label: "住宅",
        },
        {
          value: 3,
          label: "公寓",
        },
        {
          value: 4,
          label: "写字楼",
        },
        {
          value: 5,
          label: "商铺",
        },
        {
          value: 6,
          label: "别墅",
        },
      ], //房源类型
      phoneList: [], //手机列表
      tagList: [
        {
          name: "需要车位",
          checked: false,
        },
        {
          name: "需要学区",
          checked: false,
        },
        {
          name: "可带小孩",
          checked: false,
        },
        {
          name: "拎包入住",
          checked: false,
        },
        {
          name: "可带宠物",
          checked: false,
        },
        {
          name: "公司租房",
          checked: false,
        },
      ], //标签
      platList: [], //
      plotList: [], //小区地址
      purpose_house_type: [],
      finish_type: [], //
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],

        booth_type: [
          { required: true, message: "请选择客户等级", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择租售类型", trigger: "change" },
        ],
      },
      tagsList: [
        {
          name: "诚",
          checked: false,
          type: "cheng",
        },
        {
          name: "急",
          checked: false,
          type: "ji",
        },
        {
          name: "精",
          checked: false,
          type: "jing",
        },
        {
          name: "顶",
          checked: false,
          type: "ding",
        },
        {
          name: "短租",
          checked: false,
          type: "duan",
        },
        {
          name: "长租",
          checked: false,
          type: "chang",
        },
      ], //
    };
  },
  methods: {
    changePlat(e) {
      this.plotList.forEach((item) => {
        if (item.id == e) {
          this.ruleForm.purpose_little_area = item.name;
        }
      });
    },
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = res.data.data;
        });
    },
    //删除小区
    closePlta(index) {
      this.platList.splice(index, 1);
    },
    addPlot(data) {
      this.platList = data;
    },
    closePhone(index) {
      this.phoneList.splice(index, 1);
    },
    succeedPhone(e) {
      this.phoneList.push(e);
    },
    //打开弹框
    openPop(type) {
      type == 1 ? this.$refs.plot.open() : this.$refs.phone.open();
    },
    getSize(min, max, min_name, max_name) {
      if (Number(min) < Number(max)) {
        return min + "," + max;
      } else {
        let min_price = min;
        let max_price = max;
        this.ruleForm[max_name] = min_price;
        this.ruleForm[min_name] = max_price;
        return this.ruleForm[min_name] + "," + this.ruleForm[max_name];
      }
    },
    //提交
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let id = [];
          // let data = [];
          // this.ruleForm.purpose_house_type = this.purpose_house_type.join(",");
          // this.ruleForm.finish_type = this.finish_type.join(",");
          this.ruleForm.mobile = this.phoneList.join(",");
          if (
            this.ruleForm.min_purpose_area &&
            this.ruleForm.max_purpose_area
          ) {
            this.ruleForm.purpose_area = this.getSize(
              this.ruleForm.min_purpose_area,
              this.ruleForm.max_purpose_area,
              "min_purpose_area",
              "max_purpose_area"
            );
          }
          if (this.ruleForm.min_total_price && this.ruleForm.max_total_price) {
            this.ruleForm.total_price = this.getSize(
              this.ruleForm.min_total_price,
              this.ruleForm.max_total_price,
              "min_total_price",
              "max_total_price"
            );
          }

          // this.platList.forEach((item) => {
          //   id.push(item.id);
          //   data.push(item.name);
          // });
          // this.ruleForm.xiaoqu_id = id.join(",");

          // this.ruleForm.purpose_little_area = data.join(",");
          this.tagsList.forEach((em) => {
            this.ruleForm[em.type] = em.checked ? em.name : "";
          });
          this.$axios.editClient(this.ruleForm).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.go(-1);
          });
        } else {
          this.$message.error("请填写必填项");
          return false;
        }
      });
    },
    changItem(item) {
      item.checked = !item.checked;
      let data = [];
      this.tagList.forEach((item) => {
        if (item.checked) {
          data.push(item.name);
        }
      });
      this.ruleForm.client_label = data.join(",");
    },
    //选择意向位置
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      this.ruleForm.city = data.join("/");
    },
    //获取详情
    getDetails() {
      this.$axios.clientDetail({ id: this.ruleForm.id }).then((res) => {
        // this.finish_type = res.data.finish_type
        //   ? res.data.finish_type.split(",")
        //   : [];
        res.data.type = String(res.data.type);
        res.data.xiaoqu_id = Number(res.data.xiaoqu_id);
        let data = res.data.city.split("/");
        
        this.purpose_house_type = res.data.purpose_house_type
          ? res.data.purpose_house_type.split(",")
          : [];
        let areaList = [];
        this.regionList.forEach((em) => {
          if (em.label == data[0]) {
            areaList.push(em.value);
            em.children.forEach((item) => {
              if (item.label == data[1]) {
                areaList.push(item.value);
                item.children.forEach((it) => {
                  if (it.label == data[2]) {
                    areaList.push(it.value);
                  }
                });
              }
            });
          }
        });
        this.areaList = areaList;
        // let purpose_little_areas = res.data.purpose_little_area ? res.data.purpose_little_area.split(',') : []
        // let xiaoqu_ids = res.data.xiaoqu_id ? res.data.xiaoqu_id.split(',') : []
        // let plat = []
        // purpose_little_areas.forEach((item,index)=>{
        //   plat.push({name:item,id:xiaoqu_ids[index]})
        // })
        // this.platList = plat
        this.phoneList = res.data.mobile ? res.data.mobile.split(",") : [];
        let {
          sex,
          name,
          house_type,
          status,
          client_type,
          purpose_house_type,
          is_elevator,
          is_carport,
          fastest_time,
          is_school,
          pay_way,
          rent_time,
          finish_time,
          client_source,
          booth_type,
          orientation,
          client_label,
          rent_way,
          rent_ways,
          purpose_area,
          total_price,
          mobile,
          xiaoqu_id,
          purpose_little_area,
          city,
          type,
          min_purpose_area,
          max_purpose_area,
          min_total_price,
          max_total_price,
          fang_gou,
          kehu_xing,
          xuqiu_yi,
          gao_prc,
          x_daoqi,
          s_xuequ,
          s_xiaoqu,
          cheng,
          ji,
          jing,
          ding,
          duan,
          chang,
          finish_type
        } = res.data;
        this.ruleForm = {
          ...this.ruleForm,
          sex,
          name,
          house_type,
          status,
          client_type,
          purpose_house_type,
          is_elevator,
          is_carport,
          fastest_time,
          is_school,
          pay_way,
          rent_time,
          finish_time,
          client_source,
          booth_type,
          orientation,
          client_label,
          rent_way,
          rent_ways,
          purpose_area,
          total_price,
          mobile,
          xiaoqu_id,
          purpose_little_area,
          city,
          type,
          min_purpose_area,
          max_purpose_area,
          min_total_price,
          max_total_price,
          fang_gou,
          kehu_xing,
          xuqiu_yi,
          gao_prc,
          x_daoqi,
          s_xuequ,
          s_xiaoqu,
          cheng,
          ji,
          jing,
          ding,
          duan,
          chang,
          finish_type
        };
        let tagList = [];
        let tag = res.data.client_label ? res.data.client_label.split(",") : [];
        for (let i = 0; i < this.tagList.length; i++) {
          tagList.push({ name: this.tagList[i].name, checked: false });

          for (let j = 0; j < tag.length; j++) {
            if (this.tagList[i].name == tag[j]) {
              tagList[i].checked = true;
            }
          }
        }
        this.tagList = tagList;
        
        this.ruleForm.is_elevator = Number(res.data.is_elevator);
        this.ruleForm.is_carport = Number(res.data.is_carport);
        this.ruleForm.is_school = Number(res.data.is_school);
        this.ruleForm.purpose_house_type = Number(res.data.purpose_house_type);
      });
    },
  },
  created() {
    this.ruleForm.id = this.$route.query.id;
    this.getDetails();
    this.remoteMethod("");
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input {
  width: 100%;
}
.tag {
  flex-wrap: wrap;
  li {
    cursor: pointer;
    line-height: 28px;
    text-align: center;
    color: #666666;
    font-size: 14px;
    padding: 0 10px;
    height: 28px;
    background: #ebebeb;
    border-radius: 3px;
    margin: 0 10px 10px 0;
  }
  .active {
    color: #3273f6;
    background: #e1ebff;
  }
}
.addPassenger_main_head {
  padding-left: 100px;
  span {
    &:first-child {
      font-size: 18px;
      padding-left: 14px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 20px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: #3273f6;
        position: absolute;
      }
    }
    &:last-child {
      color: #3273f6;
      font-size: 16px;
    }
  }
}
.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // padding-bottom: 30px;
}
.addPassenger_main_form {
  padding: 0 100px 30px 100px;
}
/deep/ .el-form-item {
  width: 45%;
}
/deep/ .el-select,
/deep/ .el-cascader {
  width: 100%;
}
.addPassenger_main {
  padding-bottom: 60px;
}
.addPassenger_main_ul {
  padding: 0 100px 0 130px;
  li {
    display: flex;
    &:first-child {
      margin-bottom: 30px;
    }
    .addPassenger_main_ul_name {
      margin-right: 20px;
      color: #666666;
      font-size: 16px;
      flex-shrink: 0;
    }
    .addPassenger_main_ul_add {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .addPassenger_main_list {
      margin-top: 20px;
      /deep/ .el-tag {
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
.addPassenger_main_footer {
  margin-top: 60px;
  /deep/ .el-button {
    width: 680px;
    height: 56px;
  }
}
</style>
